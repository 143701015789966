const baseRoute     = '/';
let apibase = "https://api.worldtamilmusicuniversity.com/"


export const ROUTES = {
    HOME                                    : baseRoute,
    HISTORY                                 : baseRoute + 'history',
    WHYWTMU                                 : baseRoute + 'whyus',
    ACCREDITATIONANDREGISTRATION            : baseRoute + 'acregistration',
    ACCREDITATIONANDREGISTRATIONMOU         : baseRoute + 'acregistrationmou',
    VISIONANDMISSION                        : baseRoute + 'visionandmission',
    PRIVACY_POLICY                          : baseRoute + 'privacy-and-policy',
    LEADERSHIP                              : baseRoute + 'leadership',
    LEADERSHIPNEW                           : baseRoute + 'leadershipnew',
    AWARDINGDEGREE                          : baseRoute + 'awardingdegree',
    RESEARCHGUIDANCE                        : baseRoute + 'research',
    CONTACTUS                               : baseRoute + 'contactus',
    GALLERY                                 : baseRoute + 'gallery',
    VOLUNTEERPROFESSIONALS                  : baseRoute + 'volunteerprof',
    VOLUNTEERSTUDENTS                       : baseRoute + 'volunteerstudents',
    VOLUNTEERCOLOBRATIVEOFFERING            : baseRoute + 'volunteeroffer',
    VOLUNTEERSYOUTHFESTIVAL                 : baseRoute + 'volunteeryouth',
    VOLUNTEERGROUPEXCHANGEPROGRAM           : baseRoute + 'volunteergroupexchange',
    OURSTAFF                                : baseRoute + 'ourstaff',
    HONORARYADVISORS                        : baseRoute + 'honoraryadvisors',
    OURSUPPORTERS                           : baseRoute + 'oursupporters',
    UNIVERSITYHONORARYCHAIRS                : baseRoute + 'univhonorarychairs',
    CURRICULUMBOARDADVISORS                 : baseRoute + 'our-curriculum-boards-advisors',
    CURRICULUMBOARDADVISORS_P1              : baseRoute + 'our-curriculum-boards-advisors/person/1',
    CURRICULUMBOARDADVISORS_P2              : baseRoute + 'our-curriculum-boards-advisors/person/2',
    CURRICULUMBOARDADVISORS_P3              : baseRoute + 'our-curriculum-boards-advisors/person/3',
    CURRICULUMBOARDADVISORS_P4              : baseRoute + 'our-curriculum-boards-advisors/person/4',
    CURRICULUMBOARDADVISORS_P5              : baseRoute + 'our-curriculum-boards-advisors/person/5',
    CURRICULUMBOARDADVISORS_P6              : baseRoute + 'our-curriculum-boards-advisors/person/6',
    CURRICULUMBOARDADVISORS_P7              : baseRoute + 'our-curriculum-boards-advisors/person/7',
    OUR_ALLIANCE_PARTNER                    : baseRoute + 'our-alliance-partner',
    HONORARYDOCTORATES                      : baseRoute + 'honorarydoctorates',
    ACADEMICPARTNER                         : baseRoute + 'academicpartner',
    MEDIAPARTNER                            : baseRoute + 'mediapartner',
    MEETINGNOTES                            : baseRoute + 'meetingnotes',
    JOBPOSTING                              : baseRoute + 'jobposting',
    DEPARTMENTSAPPLICATIONS                 : baseRoute + 'departmentsapp',
    ANNUALREPORT                            : baseRoute + 'annualreport',
    DEPARTMENTS                             : baseRoute + 'departments',
    SOCIALPRECENSE                          : baseRoute + 'socialpresence',
    PERFOMANCE                              : baseRoute + 'perfomance',
    CERTIFICATEVERIFICATION                 : baseRoute + 'certificateverification',
    UNIVERSITYINTERNATIONALPRESENCE         : baseRoute + 'universityinternationalpresence',
    YOURFEEDBACK                            : baseRoute + 'yourfeedback',
    REGISTRATIONFORM                        : baseRoute + 'registration',
    VOLUNTEERSINDIVIDUALS                   : baseRoute +  'volunteerindividuals',
    VOLUNTEERSORGANIZATION                  : baseRoute +  'volunteerorganization',

    api : {
        gettitle                            : apibase + 'api/memberregistration/gettitlefordropdown',
        getcountry                          : apibase + 'api/memberregistration/getcountrynamelistfordropdown',
        getstate                            : apibase + 'api/memberregistration/getstatenamelistfordropdown',
        insertmember                        : apibase + 'api/memberregistration/insertregistrationdetails',
        loginmember                         : apibase + 'api/login/loginfunction',
        getCountryCode                      : apibase + 'api/memberregistration/getcountrydetailsbyid',
        forgotpasswordmailverify            : apibase + 'api/memberregistration/forgotpasswordemailverify',
        forgotpasswordotpverify             : apibase + 'api/memberregistration/forgotpasswordOTPverify',
        newpasswordverification             : apibase + 'api/memberregistration/Newpasswordverification',
        registeremailsendotp                : apibase + 'api/memberregistration/registeremailSendOtp',
        registeremailverifyotp              : apibase + 'api/memberregistration/registeremailVerifyOtp',
        updateregister                      : apibase + 'api/memberregistration/updateregister',
        insertorupdateApplicant             : apibase + 'api/memberregistration/insertorupdateapplicants',
   
        getloginotp                         : apibase + 'api/login/getloginotp',
        getcontactdetail                    : apibase + 'api/memberregistration/contactus',
        postfeedbackdetail                  : apibase + 'api/memberregistration/savefeedback',
        getactiveslider                     : apibase + 'api/contentManagement/getActiveSliders',
        getcontentbyid                      : apibase + 'api/contentManagement/get_Page_Content_ByPageID',
        getactivesliderDisplayIn            : apibase + 'api/contentManagement/getActiveSlidersHomePage',
    }
}
