import {userSession} from "./HelperService";

export const initialState = {
    user: userSession(),
    language:{}
};
type ActionType = "SET_USER" | "language";
export interface PropReducerAction {
    user?: any;
    type?: ActionType;
    data?: any;
  }

const StateService = (state: any, action: any) => {

    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.user
            }
            case "language":
            return {
                ...state,
                language: { ...action.data },
            };
        default:
            return state;
    }
};

export default StateService;
