import { Feedback, RouterSharp }            from '@mui/icons-material';
import React, {lazy}                        from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch}            from 'react-router-dom';
import {ROUTES}                             from '../config/Constants';
import AxiosProvider                        from '../providers/AxiosProvider';
import { useStateValue }                    from '../providers/StateProvider';


const Home                        = lazy(() => import('../pages/home/home'));
const whywtmu                     = lazy(() => import('../pages/whywtmu/whywtmu'));
const History                     = lazy(() => import('../pages/history/history'));
const HeaderNavBar                = lazy(() => import('../components/layouts/includes/HeaderNavBar'));
const Acreditationandregistration = lazy(() => import('../pages/acregistration/acregistration'));
const Acregistrationmou           = lazy(() => import('../pages/acregistrationMOU/acregistrationmou'));
const Visionandmission            = lazy(() =>import('../pages/visionandmission/visionmission'));
const Leadrship                   = lazy(() =>import('../pages/leadership/leadership'));
const Leadrshipnew                = lazy(() =>import('../pages/leadershipparttwo/newleadership'));
const Awardingdegree              = lazy(() =>import('../pages/awardingdegree/awarding'));
const Researchguidance            = lazy(() =>import('../pages/Researchguidance/research'));
const Contactus                   = lazy(() =>import('../pages/contactus'));
const Gallery                     = lazy(() =>import('../pages/Gallery/gallery'));
const Volunteersproffesionals     = lazy(() =>import('../pages/volunteersproffesional/voluteersproffesional'));
const Volunteersstudents          = lazy(() =>import('../pages/volunteersstudents/volunteerstud'));
const Volunteeroffer              = lazy(() =>import('../pages/volunteercolaboffer/volunteercolaboffer'));
const Volunteeryouth              = lazy(() =>import('../pages/volunteersyouthfestival/volunteersyouth'));
const Volunteergroupexchange      = lazy(() =>import('../pages/volunteersgroupexchange/volunteergroup'));
const Ourstaff                    = lazy(() =>import('../pages/ourstaff/ourstaff'));
const Honoraryadvisors            = lazy(() =>import('../pages/Honoraryadvisors/honoraryadvisors'));
const Oursupporters               = lazy(() =>import('../pages/oursupporters/oursupporters'));
const CURRICULUMBOARDADVISORS     = lazy(() =>import('../pages/ourCurriculumBoardAdvisor'));
const CURRICULUMBOARDADVISORS_1   = lazy(() =>import('../pages/ourCurriculumBoardAdvisor/person1'));
const CURRICULUMBOARDADVISORS_2   = lazy(() =>import('../pages/ourCurriculumBoardAdvisor/person2'));
const CURRICULUMBOARDADVISORS_3   = lazy(() =>import('../pages/ourCurriculumBoardAdvisor/person3'));
const CURRICULUMBOARDADVISORS_4   = lazy(() =>import('../pages/ourCurriculumBoardAdvisor/person4'));
const CURRICULUMBOARDADVISORS_5   = lazy(() =>import('../pages/ourCurriculumBoardAdvisor/person5'));
const CURRICULUMBOARDADVISORS_6   = lazy(() =>import('../pages/ourCurriculumBoardAdvisor/person6'));
const CURRICULUMBOARDADVISORS_7   = lazy(() =>import('../pages/ourCurriculumBoardAdvisor/person7'));
const OUR_ALLIANCE_PARTNER        = lazy(() =>import('../pages/ourAlliancePartner'));
const Universityhonorarychairs    = lazy(() =>import('../pages/universityhonorarychairs/university'));
const Honorarydoctorates          = lazy(() =>import('../pages/honorarydoctorates/doctorate'));
const Academicpartner             = lazy(() =>import('../pages/academicpartner/academic'));
const Mediapartner                = lazy(() =>import('../pages/mediapartner/mediapartner'));
const Meetingnotes                = lazy(() =>import('../pages/meetingnotes/meetingnotes'));
const Jobposting                  = lazy(() =>import('../pages/jobposting/jobposting'));
const Departmentsapp              = lazy(() =>import('../pages/departmentsapplication/departmentsapp'));
const Annualreport                = lazy(() =>import('../pages/annualreport/annualreport'));
const Departments                 = lazy(() =>import('../pages/departments/departments'));
const Socialpresence              = lazy(() =>import('../pages/socialpresence/social'));
const Perfomance                  = lazy(() =>import('../pages/perfomance/perfomance'));
const Certificateverification     = lazy(() =>import('../pages/certificateverification/certificate'));
const Universityinterpresence     = lazy(() =>import('../pages/universityintpresence/international'));
const Yourfeedback                = lazy(() =>import('../pages/yourfeedback/feedback'));
const Registrationform            = lazy(() =>import('../pages/registrationform/registration'));
const Volunteerindividuals        = lazy(() =>import('../pages/volunteerindividuals/volunteerindividuals'));
const Volunteeroraganization      = lazy(() =>import('../pages/volunteerorganization/volunteerorganization'));
const PrivacyPolicy               = lazy(() =>import('../pages/privacyAndPolicy'));



function RouteApp() {
    return (<>
        {/* <AxiosProvider/> */}
        
        <Switch>
            <Route exact path={ROUTES.HOME} component={Home} />
            <Route exact path={ROUTES.HISTORY} component={History} />
            <Route exact path={ROUTES.WHYWTMU} component={whywtmu} />
            <Route exact path={ROUTES.ACCREDITATIONANDREGISTRATION} component={Acreditationandregistration} />
            <Route exact path={ROUTES.ACCREDITATIONANDREGISTRATIONMOU} component={Acregistrationmou} />
            <Route exact path={ROUTES.VISIONANDMISSION} component={Visionandmission} />
            <Route exact path={ROUTES.LEADERSHIP} component={Leadrship} />
            <Route exact path={ROUTES.LEADERSHIPNEW} component={Leadrshipnew} />
            <Route exact path={ROUTES.AWARDINGDEGREE} component={Awardingdegree} />
            <Route exact path={ROUTES.RESEARCHGUIDANCE} component={Researchguidance} />
            <Route exact path={ROUTES.CONTACTUS} component={Contactus} />
            <Route exact path={ROUTES.GALLERY} component={Gallery} />
            <Route exact path={ROUTES.VOLUNTEERPROFESSIONALS} component={Volunteersproffesionals} />
            <Route exact path={ROUTES.VOLUNTEERSTUDENTS} component={Volunteersstudents} />
            <Route exact path={ROUTES.VOLUNTEERCOLOBRATIVEOFFERING} component={Volunteeroffer} />
            <Route exact path={ROUTES.VOLUNTEERSYOUTHFESTIVAL} component={Volunteeryouth} />
            <Route exact path={ROUTES.VOLUNTEERGROUPEXCHANGEPROGRAM} component={Volunteergroupexchange} />
            <Route exact path={ROUTES.OURSTAFF} component={Ourstaff} />
            <Route exact path={ROUTES.HONORARYADVISORS} component={Honoraryadvisors} />
            <Route exact path={ROUTES.OURSUPPORTERS} component={Oursupporters} />
            <Route exact path={ROUTES.CURRICULUMBOARDADVISORS} component={CURRICULUMBOARDADVISORS} />
            <Route exact path={ROUTES.CURRICULUMBOARDADVISORS_P1} component={CURRICULUMBOARDADVISORS_1} />
            <Route exact path={ROUTES.CURRICULUMBOARDADVISORS_P2} component={CURRICULUMBOARDADVISORS_2} />
            <Route exact path={ROUTES.CURRICULUMBOARDADVISORS_P3} component={CURRICULUMBOARDADVISORS_3} />
            <Route exact path={ROUTES.CURRICULUMBOARDADVISORS_P4} component={CURRICULUMBOARDADVISORS_4} />
            <Route exact path={ROUTES.CURRICULUMBOARDADVISORS_P5} component={CURRICULUMBOARDADVISORS_5} />
            <Route exact path={ROUTES.CURRICULUMBOARDADVISORS_P6} component={CURRICULUMBOARDADVISORS_6} />
            <Route exact path={ROUTES.CURRICULUMBOARDADVISORS_P7} component={CURRICULUMBOARDADVISORS_7} />
            <Route exact path={ROUTES.OUR_ALLIANCE_PARTNER} component={OUR_ALLIANCE_PARTNER} />
            <Route exact path={ROUTES.UNIVERSITYHONORARYCHAIRS} component={Universityhonorarychairs} />
            <Route exact path={ROUTES.HONORARYDOCTORATES} component={Honorarydoctorates} />
            <Route exact path={ROUTES.ACADEMICPARTNER} component={Academicpartner} />
            <Route exact path={ROUTES.MEDIAPARTNER} component={Mediapartner} />
            <Route exact path={ROUTES.MEETINGNOTES} component={Meetingnotes} />
            <Route exact path={ROUTES.JOBPOSTING} component={Jobposting} />
            <Route exact path={ROUTES.DEPARTMENTSAPPLICATIONS} component={Departmentsapp} />
            <Route exact path={ROUTES.ANNUALREPORT} component={Annualreport} />
            <Route exact path={ROUTES.DEPARTMENTS} component={Departments} />
            <Route exact path={ROUTES.SOCIALPRECENSE} component={Socialpresence} />
            <Route exact path={ROUTES.PERFOMANCE} component={Perfomance} />
            <Route exact path={ROUTES.CERTIFICATEVERIFICATION} component={Certificateverification} />
            <Route exact path={ROUTES.UNIVERSITYINTERNATIONALPRESENCE} component={Universityinterpresence} />
            <Route exact path={ROUTES.YOURFEEDBACK} component={Yourfeedback} />
            <Route exact path={ROUTES.REGISTRATIONFORM} component={Registrationform}/>
            <Route exact path={ROUTES.VOLUNTEERSORGANIZATION} component={Volunteeroraganization}/>
            <Route exact path={ROUTES.VOLUNTEERSINDIVIDUALS} component={Volunteerindividuals}/>
            <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy}/>





            {/* <PublicRoute exact path={ROUTES.HOME}                    component={Home}/>
            <PublicRoute exact path={ROUTES.WHYWTMU}                 component={whywtmu}/> */}
            {/* <NavRoute path={ROUTES.WHYWTMU}                    component={whywtmu}/> */}
        </Switch>
        
    </>)
}

const NavRoute: React.FC<any> = ({component: Component, restricted, ...rest}) => {

    const [{user}]: any = useStateValue();
    return (     
        <Route {...rest} render={(props) => (
            user ?
            <div>              
              <Component {...props}/>
            </div> : <Component/> 
          )}/>
        
    );
};

const PublicRoute: React.FC<any> = ({component: Component, restricted, ...rest}) => {
    //const [{user}]: any = useStateValue();

    return (       
        <Route {...rest} render={props => (<Component {...props} /> )}/>
    );
};

export default RouteApp;
