import React                     from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import AppLayout                 from "../components/layouts/AppLayout";
import AppThemeProvider          from "../providers/AppThemeProvider";

function RouteInit() {
    return (
        <Router>
            <AppThemeProvider>
                <AppLayout/>
            </AppThemeProvider>
        </Router>
    );
}

export default RouteInit;