import React, {lazy, Suspense, useState} from 'react';
import RouteApp                          from "../../routes/RouteApp";
import {LazyLoad}                        from "../../providers/HelperProvider";
import {useStateValue}                   from "../../providers/StateProvider";
import clsx                              from 'clsx';
const HeaderNavBar = lazy(() => import('./includes/HeaderNavBar'));
const Footer=lazy(()=>import('./includes/Footer'));

function AppLayout() {
    return (<>
        <main>            
            <div>
                <Suspense fallback={<LazyLoad/>}>
                    <HeaderNavBar/>
                    <RouteApp/>
                    <Footer/>
                </Suspense>
            </div>
        </main>
    </>);
}

export default AppLayout;
